define([
    'santa-components',
    'lodash',
    'zepto',
    'prop-types',
    'create-react-class',
    'reactDOM',
    'mobx',
    'mobx-react',
    'coreUtils',
    'compUtils/core/compFactory',
    'compUtils/core/MagicStructure',
    'compUtils/core/progressiveReveal',
    'compUtils/core/utils/domOnlyUtils',
    'santaProps',
    'experiment'
],
function (
    santaComponents,
    _,
    $,
    PropTypes,
    createReactClass,
    ReactDOM,
    mobx,
    mobxReact,
    coreUtils,
    compFactory,
    MagicStructure,
    progressiveReveal,
    domOnlyUtils,
    santaProps,
    experiment
) {
    'use strict';

    const triggerTypesConsts = coreUtils.triggerTypesConsts;

    const computedPropsBuilder = santaProps.computedPropsBuilder;
    const propsBuilderUtil = santaProps.propsBuilderUtil;

    const Animations = {
        ENTER: coreUtils.siteConstants.Animations.Modes.AnimationType.ENTER,
        LEAVE: coreUtils.siteConstants.Animations.Modes.AnimationType.LEAVE,
        TRANSITION: coreUtils.siteConstants.Animations.Modes.AnimationType.TRANSITION
    };

    const LIVE_COMP_PROPS = ['mobxObserverWrapperProps', 'childrenSet', 'pagePointer'];
    const DEAD_COMP_PROPS = {
        'data-dead-comp': true
    };

    function isSitePagesComponent(structureId) {
        return structureId === 'SITE_PAGES';
    }

    function createState(siteAPI) {
        return {
            fetchSantaType: (santaTypesDefinition, state, props) => siteAPI.getSantaFetcher(santaTypesDefinition)(state, props),
            siteData: siteAPI.getSiteData(),
            siteAPI
        };
    }

    function createProps(siteAPI, pageId, compStructure, compId) {
        const compPageId = _.isUndefined(pageId) ? 'masterPage' : pageId;
        const navigationInfo = siteAPI.getSiteData().getExistingRootNavigationInfo(compPageId);

        return {
            structure: compStructure,
            rootNavigationInfo: navigationInfo,
            rootId: compPageId,
            id: compId
        };
    }

    function createCompProps(siteAPI, compClass, pageId, compStructure, compId) {
        const state = createState(siteAPI);
        const props = createProps(siteAPI, pageId, compStructure, compId);
        const shouldObserveChanges = !!_.get(compClass, 'shouldLayout', false);
        const computedProps = computedPropsBuilder.getInstance(state, props, compClass.propTypes, 'santaTypes', shouldObserveChanges).getComputedProps(props);
        return computedProps.get();
    }

    function buildComponentProps(props, isAffectedByModeChanges, isTransitioning, compStructure, compClass) {
        const siteAPI = props.mobxObserverWrapperProps.siteAPI;
        const compProps = createCompProps(siteAPI, compClass, props.rootId, compStructure, props.id);

        if (isSitePagesComponent(props.id)) {
            _.assign(compProps, props.mobxObserverWrapperProps.propsForSitePages);
        }

        compProps.isAffectedByModeChanges = isAffectedByModeChanges;
        if (isTransitioning) {
            compProps.className = `${compProps.className || ''} transitioning-comp`;
        }

        return compProps;
    }

    function getDisplayName(compClass, fullCompClassName) {
        return `SantaTypeObserver(${compClass.displayName || compClass.name || fullCompClassName.split('.').pop()})`;
    }

    function getComponentConstructor(componentType) {
        return componentType && compFactory.getCompClass(componentType) || santaComponents.utils.createReactElement.bind(null, 'div'); // eslint-disable-line no-mixed-operators
    }

    function getCompPropsFromProps(compClass, compStructure, displayedChildrenIds, didModesChange, nextProps, prevCompPropsFromProps) {
        const isStartingTransition = didModesChange && !!prevCompPropsFromProps;
        //pass comp animation type on props from parent - cause it's calculated on parent level
        const isAlreadyTransitioning = nextProps.compAnimationType === Animations.TRANSITION;
        const isTransitioning = isAlreadyTransitioning || isStartingTransition;
        const compProps = buildComponentProps(nextProps, didModesChange, isTransitioning, compStructure, compClass);
        compProps.childrenSet = displayedChildrenIds;

        return compProps;
    }

    function areAnyAnimationsPlaying(currentlyPlayingComponentAnimations) {
        return !_.isEmpty(currentlyPlayingComponentAnimations);
    }

    function calculateAreChildrenAffectedByModes(isParentAffected, modeChanges, childrenToRender) {
        const isAffectedArray = _.map(childrenToRender, function (childStructure) {
            if (isParentAffected) {
                return true;
            }
            return anyChangeInActiveModesOfComponent(childStructure, modeChanges);
        });

        return _.zipObject(_.map(childrenToRender, 'id'), isAffectedArray);
    }

    function createCompToAnimationTypeMap(prevCompProps, nextCompProps, areChildrenAffectedByModesMap) {
        const prevChildren = _.keyBy(prevCompProps.childrenSet, 'id');
        const nextChildren = _.keyBy(nextCompProps.childrenSet, 'id');

        const childrenAnimations = _.reduce(nextChildren, function (acc, comp, compId) {
            const hasPrev = !!prevChildren[compId];
            return hasPrev ? acc : _.set(acc, compId, Animations.ENTER);
        }, {});

        return _.reduce(prevChildren, function (acc, comp, compId) {
            const hasNext = !!nextChildren[compId];

            if (!hasNext) {
                return _.set(acc, compId, Animations.LEAVE);
            }

            if (areChildrenAffectedByModesMap[compId]) {
                return _.set(acc, compId, Animations.TRANSITION);
            }

            return acc;
        }, childrenAnimations);
    }

    function anyChangeInActiveModesOfComponent(compStructure, modeChanges) {
        let changesInActiveModesOfComponent = [];

        const compModeDefinitions = _.get(compStructure, 'modes.definitions');
        if (compModeDefinitions && compModeDefinitions.length) {
            const compModeDefinitionIds = _.map(compModeDefinitions, 'modeId');
            const idsOfModesThatChangedActiveStatus = _.keys(modeChanges);
            changesInActiveModesOfComponent = _.intersection(idsOfModesThatChangedActiveStatus, compModeDefinitionIds);
        }

        return !_.isEmpty(changesInActiveModesOfComponent);
    }

    function getLayoutForTransitioningChildren(siteAPI, childrenAnimationsMap, childrenData) {
        const siteData = siteAPI.getSiteData();
        const measureMap = siteData.measureMap;
        if (!measureMap) {
            return {};
        }

        const scrollAspect = siteAPI.getSiteAspect('windowScrollEvent');
        const scrollPosition = scrollAspect.getScrollPosition();
        const isTransitionAnimation = _.partial(_.isEqual, Animations.TRANSITION);
        return _(childrenAnimationsMap)
            .pickBy(isTransitionAnimation)
            .mapValues(function (transitionType, compId) {
                return {
                    width: measureMap.width[compId],
                    height: measureMap.height[compId],
                    left: measureMap.absoluteLeft[compId] - scrollPosition.x,
                    top: measureMap.absoluteTop[compId] - scrollPosition.y,
                    rotation: _.get(childrenData, ['rotationDegrees', compId])
                };
            })
            .value();
    }

    function getLeavingChildrenIds(siteAPI, childrenAnimationsMap) {
        return _(childrenAnimationsMap)
            .pickBy(type => type === Animations.LEAVE)
            .keys()
            .value();
    }

    function triggerModeAnimationsInit(pageId, siteAPI, modeChanges, childrenAnimations, transitioningChildrenPrevLayout, onComplete) {
        const actionsAspect = siteAPI.getSiteAspect('actionsAspect');
        actionsAspect.executeAction('modeChange', triggerTypesConsts.MODE_CHANGED_INIT, {
            modeChanges,
            componentAnimations: childrenAnimations,
            transitioningComponentsPrevLayout: transitioningChildrenPrevLayout,
            pageId,
            onComplete
        });
    }

    // return array: [{id, componentType}]
    function getChildrenToRenderDuringModeChange(prevCompProps, nextCompProps, renderedChildren, currentlyPlayingCompAnimations) {
        const childrenWithPlayingAnimations = _.filter(renderedChildren, function (child) {
            return !!currentlyPlayingCompAnimations[child.id];
        });
        return _.unionBy(prevCompProps.childrenSet, childrenWithPlayingAnimations, nextCompProps.childrenSet, 'id');
    }

    function triggerModeAnimationsExecute(siteAPI) {
        const actionsAspect = siteAPI.getSiteAspect('actionsAspect');
        actionsAspect.executeAction('modeChange', triggerTypesConsts.MODE_CHANGED_EXECUTE);
    }

    function getChildrenInfo(children, displayedDAL, pointers) {
        return _.map(children, function (childPointer) {
            return {
                id: displayedDAL.get(pointers.getInnerPointer(childPointer, ['id'])),
                componentType: displayedDAL.get(pointers.getInnerPointer(childPointer, ['componentType']))
            };
        });
    }

    function trackChildrenArray(compPointer, pointers, displayedDAL) {
        const childrenArrayPointer = compPointer && pointers.components.getChildrenContainer(compPointer);
        return childrenArrayPointer && displayedDAL.get(childrenArrayPointer);
    }

    function createMobxComputedFields() {
        const {mobxObserverWrapperProps} = this.props;
        const displayedDAL = mobxObserverWrapperProps.siteAPI.getDisplayedDAL();
        const pointers = mobxObserverWrapperProps.siteAPI.getPointers();
        const siteData = mobxObserverWrapperProps.siteAPI.getSiteData();

        this.pagePointer = this.props.pagePointer || mobx.computed(function () {
            const viewMode = siteData.getViewMode();
            return pointers.components.getPage(this.props.rootId, viewMode) || pointers.components.getMasterPage(viewMode);
        }, {context: this, compareStructural: true, name: `pagePointer_${this.props.id}`});

        this.childrenPointers = mobx.computed(function () {
            const pagePointer = this.pagePointer.get();
            const compPointer = pointers.components.getComponent(this.props.id, pagePointer);

            trackChildrenArray(compPointer, pointers, displayedDAL);

            if (!compPointer) {
                return [];
            }

            const pageStubComps = displayedDAL.getByPath(['pageStubComponents', pagePointer.id]);
            return _.filter(pointers.components.getChildren(compPointer), child => !pageStubComps || pageStubComps[child.id]);
        }, {context: this, compareStructural: true, name: `childrenPointers_${this.props.id}`});

        this.computedChildrenToRender = mobx.computed(function () {
            return getChildrenInfo(this.childrenPointers.get(), displayedDAL, pointers);
        }, {context: this, compareStructural: true, name: `computedChildrenToRender_${this.props.id}`});

        this.magicStructure = new MagicStructure(siteData, displayedDAL, pointers, this.props.id, this.pagePointer, this.computedChildrenToRender, this.props.computedParentDimensions);

        this.computedDimensions = mobx.computed(function () {
            return this.props.structure ? {} : this.magicStructure.dimensions;
        }, {name: `computedDimensions_${this.props.id}`, context: this});

        this.compModes = mobx.computed(function () {
            const definitions = _.get(this.magicStructure.modes, 'definitions');
            if (_.isEmpty(definitions)) {
                return {};
            }

            const rootId = this.pagePointer.get().id;
            const pageActiveModesPointer = pointers.activeModes.getPageActiveModes(rootId);
            const currentRootModes = displayedDAL.get(pageActiveModesPointer);

            return _(currentRootModes)
                .mapValues(function (modeData, modeId) {
                    return _.some(definitions, {modeId});
                })
                .pickBy()
                .value();
        }, {context: this, compareStructural: true, name: `${this.props.id}_activeModes`});

        this.compBehaviors = mobx.computed(function () {
            const siteAPI = this.props.mobxObserverWrapperProps.siteAPI;
            const compStructure = this.props.structure || this.magicStructure;
            const behaviorQuery = compStructure.behaviorQuery;
            const propertyQuery = compStructure.propertyQuery;
            return propsBuilderUtil.getCompBehaviors(siteAPI, propertyQuery, behaviorQuery, this.props.rootId, this.props.id);
        }, {context: this, compareStructural: true, name: `${this.props.id}_compBehaviors`});

        this.shouldReveal = mobx.computed(function () {
            const y = this.calcComponentTop();
            const reveal = progressiveReveal.get(this.props.mobxObserverWrapperProps.siteAPI);
            return !(y > reveal);
        }, {context: this, name: `${this.props.id}_reveal`});
    }

    function registerCompBehaviors() {
        const siteAPI = this.props.mobxObserverWrapperProps.siteAPI;
        const pointers = siteAPI.getPointers();
        const displayedDAL = siteAPI.getDisplayedDAL();
        const pagePointer = this.pagePointer.get();
        const compPointer = pointers.components.getComponent(this.props.id, pagePointer);
        if (this.props.structure || compPointer && displayedDAL.isExist(compPointer)) { // eslint-disable-line no-mixed-operators
            siteAPI.registerCompBehaviours(this.props.rootId, this.props.id, this.compBehaviors.get());
        }
    }

    function createMobxReactions() {
        return mobx.observe(this.compBehaviors, registerCompBehaviors.bind(this), {});
    }

    function getZIndex(siteAPI, compId, isFixedPosition) {
        let zIndex;
        const shouldShowOnTop = _.includes(siteAPI.getRenderRealtimeConfigItem('compsToShowOnTop'), compId);
        if (shouldShowOnTop) {
            zIndex = coreUtils.style.MAX_Z_INDEX;
        } else if (isFixedPosition) {
            const renderFixedPositionContainers = siteAPI.getRenderFlag('renderFixedPositionContainers');
            zIndex = renderFixedPositionContainers ? 50 : 49;
        } else {
            zIndex = '';
        }
        return zIndex;
    }

    function applyDOMStyle(siteAPI, rootId, compId, style, isFixedPosition) {
        const comp = siteAPI.getComponentByPageAndCompId(rootId, compId);

        if (!comp) {
            return;
        }
        const styleToApply = _.clone(style);

        styleToApply.zIndex = getZIndex(siteAPI, compId, isFixedPosition);

        if (comp.hasOwnProperty('getRootStyle')) {
            _.assign(styleToApply, comp.getRootStyle(styleToApply));
        }

        $(ReactDOM.findDOMNode(comp)).css(styleToApply);
    }

    return function mobxObserverWrapper(name, compClass) {
        return mobxReact.observer(
            createReactClass({
                displayName: getDisplayName(compClass, name),
                mixins: [coreUtils.renderDoneMixin],
                propTypes: {
                    id: PropTypes.string.isRequired,
                    structure: PropTypes.object,
                    rootId: PropTypes.string,
                    mobxObserverWrapperProps: PropTypes.object.isRequired,
                    anyChangeInParentActiveModes: PropTypes.bool,
                    applyStyleOverrides: PropTypes.bool,
                    calcParentY: PropTypes.func
                },

                componentDidCatch(error, info) {
                    coreUtils.integrations.ravenUtils.captureError(error, {
                        tags: {
                            compType: this.props.structure && this.props.structure.componentType
                        },
                        extra: {
                            id: this.props.id,
                            info
                        }
                    });
                },

                componentWillMount() {
                    this.transitioningChildrenPrevLayout = {};
                    this.currentlyAnimatingChildren = {};
                    this.childrenAnimations = {};
                    this.renderedCompProps = {};
                    this.childrenToRender = {};
                    this._lastRenderedComp = null;
                    this.stylesToApplyDomOnly = null;
                    createMobxComputedFields.call(this);
                    this.prevActiveModes = null;
                    this.disposeReactions = createMobxReactions.call(this);
                    this.props.mobxObserverWrapperProps.siteAPI.setComponentRenderStart(this.props.id || this.props.structure.id);

                    const siteAPI = this.props.mobxObserverWrapperProps.siteAPI;
                    const compStructure = this.props.structure || this.magicStructure;
                    const state = createState(siteAPI);
                    const props = createProps(siteAPI, this.props.rootId, compStructure, this.props.id);
                    const shouldObserveChanges = !!_.get(compClass, 'shouldLayout', false);
                    this.computedPropsManager = computedPropsBuilder.getInstance(state, props, compClass.propTypes, 'santaTypes', shouldObserveChanges);

                    progressiveReveal.reset(siteAPI);
                },

                componentWillUpdate() {
                    this.setComponentRenderStartIfNeeded();
                },

                componentWillReact() {
                    const siteData = this.props.mobxObserverWrapperProps.siteAPI.getSiteData();
                    siteData.reacted = true;

                    this.setComponentRenderStartIfNeeded();
                },

                componentWillUnmount() {
                    const siteAPI = this.props.mobxObserverWrapperProps.siteAPI;
                    siteAPI.cancelReLayoutPending(this.props.id || this.props.structure.id);
                    siteAPI.unregisterCompBehaviours(this.props.rootId, this.props.id || this.props.structure.id);
                    _.invoke(this, 'disposeReactions');
                },

                componentDidMount() {
                    const siteAPI = this.props.mobxObserverWrapperProps.siteAPI;

                    registerCompBehaviors.call(this);
                    this.prevActiveModes = this.compModes.get();
                    if (coreUtils.displayedOnlyStructureUtil.isRepeatedComponent(this.props.id)) {
                        siteAPI.requestEnforceAnchors();
                    }

                    siteAPI.setComponentRenderEnd(this.props.id || this.props.structure.id);

                    if (this._isRevealed === false) {
                        progressiveReveal.auto(siteAPI);
                    }
                },

                getModesChanges() {
                    const currentModes = this.compModes.get();
                    if (!this.prevActiveModes) {
                        return {};
                    }
                    return coreUtils.modesUtils.getModeChanges(this.prevActiveModes, currentModes);
                },

                componentDidUpdate() { // eslint-disable-line complexity
                    const siteAPI = this.props.mobxObserverWrapperProps.siteAPI;
                    const isContextReadyOrLifeCycleFailed = experiment.isOpen('sv_handleFailingWixCodeSdk', siteAPI.getSiteData()) ? siteAPI.getSiteAspect('WidgetAspect').isContextReadyOrLifeCycleFailed(this.props.rootId) : siteAPI.getSiteAspect('WidgetAspect').isContextReady(this.props.rootId);

                    if (this.stylesToApplyDomOnly && siteAPI.isDuringPostUpdatesOperations(this.props.id) || !isContextReadyOrLifeCycleFailed) { // eslint-disable-line no-mixed-operators
                        siteAPI.removePostUpdateOperationsRender(this.props.id);
                        siteAPI.cancelReLayoutPending(this.props.id);
                        return;
                    }

                    if (areAnyAnimationsPlaying(this.childrenAnimations)) {
                        triggerModeAnimationsInit(this.props.rootId, siteAPI, this.getModesChanges(), this.childrenAnimations, this.transitioningChildrenPrevLayout, this.handleModeChangeAnimationsFinished);
                        triggerModeAnimationsExecute(siteAPI);
                        this.childrenAnimations = {};
                        this.transitioningChildrenPrevLayout = {};
                        this.leavingChildrenIds = {};
                    }

                    if (this.stylesToApplyDomOnly) {
                        const isFixedPosition = _.get(this.magicStructure, 'layout.fixedPosition', false);
                        applyDOMStyle(siteAPI, this.props.rootId, this.props.id, this.stylesToApplyDomOnly, isFixedPosition);
                        if (compClass.applyCompSpecificDomOnlyPatches) {
                            compClass.applyCompSpecificDomOnlyPatches(this, this.renderedCompProps.style);
                        }
                        this.stylesToApplyDomOnly = null;
                    }

                    const pointers = this.props.mobxObserverWrapperProps.siteAPI.getPointers();
                    const displayedDAL = this.props.mobxObserverWrapperProps.siteAPI.getDisplayedDAL();
                    const pagePointer = this.pagePointer.get();
                    const computedChildrenToRender = this.computedChildrenToRender.get();
                    this.childrenData = {
                        rotationDegrees: _.reduce(computedChildrenToRender, function (acc, childInfo) {
                            const childId = childInfo.id;
                            const compPointer = pointers.components.getComponent(childId, pagePointer);
                            return _.set(acc, childId, displayedDAL.get(pointers.getInnerPointer(compPointer, 'layout.rotationInDegrees')));
                        }, {})
                    };
                    this.prevActiveModes = this.compModes.get();

                    if (coreUtils.displayedOnlyStructureUtil.isRepeatedComponent(this.props.id)) {
                        siteAPI.requestEnforceAnchors();
                    }

                    this.computedPropsManager.clearChangedPropsMap();

                    const id = this.props.id || this.props.structure.id;

                    if (this.shouldLayout) {
                        siteAPI.setComponentRenderEnd(id);
                    }
                },

                getRenderedCompProps(nextProps, prevCompProp) {
                    const siteAPI = nextProps.mobxObserverWrapperProps.siteAPI;
                    const currentlyAnimatingChildren = this.currentlyAnimatingChildren;
                    const modeChanges = this.getModesChanges();
                    const didModesChange = !_.isEmpty(modeChanges);

                    //calculate self comp props
                    const compStructure = nextProps.structure || this.magicStructure;
                    const nextCompProps = getCompPropsFromProps(compClass, compStructure,
                        this.props.structure ? [] : this.computedChildrenToRender.get(),
                        didModesChange,
                        nextProps,
                        prevCompProp
                    );

                    if (didModesChange) {
                        //calculate animation types to component direct children
                        const areChildrenAffectedByModesMap = calculateAreChildrenAffectedByModes(nextCompProps.isAffectedByModeChanges, modeChanges, this.computedChildrenToRender.get());
                        this.childrenAnimations = createCompToAnimationTypeMap(prevCompProp, nextCompProps, areChildrenAffectedByModesMap);
                        this.transitioningChildrenPrevLayout = getLayoutForTransitioningChildren(siteAPI, this.childrenAnimations, this.childrenData);
                        this.leavingChildrenIds = getLeavingChildrenIds(siteAPI, this.childrenAnimations);
                        _.assign(currentlyAnimatingChildren, this.childrenAnimations);
                    }

                    if (areAnyAnimationsPlaying(currentlyAnimatingChildren)) {
                        //decide which children should be rendered
                        //add children from structure here too
                        this.childrenToRender = getChildrenToRenderDuringModeChange(prevCompProp, nextCompProps, this.childrenToRender, currentlyAnimatingChildren);
                        nextCompProps.leavingChildrenIds = this.leavingChildrenIds;
                    } else {
                        this.childrenToRender = nextCompProps.childrenSet;
                    }

                    return _.assign({}, nextCompProps, {ref: this.compRefFunction}, this.props);
                },

                compRefFunction(component) {
                    if (this.props.mobxObserverWrapperProps.addComponentRef) {
                        this.props.mobxObserverWrapperProps.addComponentRef(component, this.props.id);
                    }
                },

                calcComponentTop() {
                    const siteData = this.props.mobxObserverWrapperProps.siteAPI.getSiteData();
                    const y = (this.computedDimensions.get().y || 0) + (this.props.calcParentY ? this.props.calcParentY() : 0);
                    const absoluteTop = siteData.measureMap && siteData.measureMap.absoluteTop && siteData.measureMap.absoluteTop[this.props.id];
                    return absoluteTop < y ? absoluteTop : y;
                },

                getChildProps(childId) {
                    return {
                        id: childId,
                        rootId: this.props.rootId,
                        anyChangeInParentActiveModes: !!(this.props.anyChangeInParentActiveModes || !_.isEmpty(this.getModesChanges())),
                        computedParentDimensions: this.computedDimensions,
                        mobxObserverWrapperProps: this.props.mobxObserverWrapperProps,
                        pagePointer: this.pagePointer,
                        key: childId,
                        refInParent: childId,
                        calcParentY: this.calcComponentTop
                    };
                },

                setComponentRenderStartIfNeeded() {
                    const checkShouldLayout = () => {
                        const changedProps = this.computedPropsManager.getChangedProps();

                        const {shouldLayout} = compClass;

                        if (shouldLayout) {
                            if (_.isFunction(shouldLayout)) {
                                return shouldLayout(changedProps);
                            } else if (_.isObject(shouldLayout)) {
                                return _.some(changedProps, (wasChanged, propName) => _.isUndefined(shouldLayout[propName]) || shouldLayout[propName]);
                            }
                        }

                        return true;
                    };

                    this.shouldLayout = checkShouldLayout();

                    if (this.shouldLayout) {
                        this.props.mobxObserverWrapperProps.siteAPI.setComponentRenderStart(this.props.id || this.props.structure.id);
                    }
                },

                render() { // eslint-disable-line complexity
                    const siteAPI = this.props.mobxObserverWrapperProps.siteAPI;
                    const isContextReadyOrLifeCycleFailed = experiment.isOpen('sv_handleFailingWixCodeSdk', siteAPI.getSiteData()) ? siteAPI.getSiteAspect('WidgetAspect').isContextReadyOrLifeCycleFailed(this.props.rootId) : siteAPI.getSiteAspect('WidgetAspect').isContextReady(this.props.rootId);

                    if (!this.props.structure && !this.magicStructure.id || !isContextReadyOrLifeCycleFailed) { // eslint-disable-line no-mixed-operators
                        return this._lastRenderedComp;
                    }

                    const prevCompProps = _.clone(this.renderedCompProps);
                    this.renderedCompProps = this.getRenderedCompProps(this.props, prevCompProps);

                    const structure = this.props.structure || this.magicStructure;
                    const isDocked = !!_.get(structure, ['layout', 'docked']);
                    const didUnstretch = this.wasDocked && !isDocked;
                    const canBeDomOnly =
                        !didUnstretch &&
                        siteAPI.getLayoutMechanism() === coreUtils.constants.LAYOUT_MECHANISMS.ANCHORS;
                    this.wasDocked = isDocked;
                    this.stylesToApplyDomOnly = canBeDomOnly &&
                        domOnlyUtils.getStylesToApplyDomOnly(prevCompProps, this.renderedCompProps, compClass, structure);

                    if (this.stylesToApplyDomOnly) {
                        return this._lastRenderedComp;
                    }

                    const isRevealed = this._isRevealed;
                    this._isRevealed = isRevealed || this.shouldReveal.get();

                    let cc;
                    let compProps;
                    let compChildren;
                    if (this._isRevealed) {
                        cc = compClass;
                        compProps = _.omit(this.renderedCompProps, LIVE_COMP_PROPS);
                        compChildren = _.map(this.childrenToRender, child => {
                            const reactConstructor = getComponentConstructor(child.componentType);
                            const childProps = this.getChildProps(child.id);
                            return reactConstructor(childProps);
                        });
                        if (isRevealed === false) {
                            progressiveReveal.decNotRevealedCount(siteAPI);
                        }
                    } else {
                        cc = 'div';
                        compProps = DEAD_COMP_PROPS;
                        if (isRevealed !== false) {
                            progressiveReveal.incNotRevealedCount(siteAPI);
                        }
                    }

                    this._lastRenderedComp = santaComponents.utils.createReactElement(cc, compProps, compChildren);
                    return this._lastRenderedComp;
                },

                handleModeChangeAnimationsFinished(finishedComponentAnimations) {
                    this.currentlyAnimatingChildren = _.omit(this.currentlyAnimatingChildren, _.keys(finishedComponentAnimations));
                    if (!areAnyAnimationsPlaying(this.currentlyAnimatingChildren)) {
                        this.forceUpdate();
                    }
                }
            })
        );
    };
});
