define([], function () {
    'use strict';

    const IDLE_DURATIN = 50;

    return typeof window !== 'undefined' && window.requestIdleCallback ?
        {
            request: window.requestIdleCallback.bind(window),
            cancel: window.cancelIdleCallback.bind(window)
        } :
        {
            request(cb) {
                const start = Date.now();
                return setTimeout(() => {
                    cb({
                        didTimeout: false,
                        timeRemaining: () => Math.max(0, IDLE_DURATIN - (Date.now() - start))
                    });
                }, 1);
            },
            cancel(id) {
                clearTimeout(id);
            }
        };
});

