define(['lodash', 'mobx', 'coreUtils', 'compUtils/core/idleCallback'], function (_, mobx, coreUtils, idleCallback) {
    'use strict';

    const DEF_HEIGHT = 1080;
    const INIT_RENDER_PROGRESS_PART = 16;
    const RENDER_PROGRESS_PART_DELTA = 2;
    const EXTRA_REVEAL = 8;

    const privates = new coreUtils.SiteDataPrivates(); //eslint-disable-line santa/no-module-state

    function Private(siteAPI, siteData) {
        this._siteAPI = siteAPI;
        this._siteData = siteData;
        this._primaryPageId = '';
        this._revealLimit = mobx.observable.box(0);
        this._renderProgressPart = INIT_RENDER_PROGRESS_PART;
        this._revealId = 0;
        this._maxScroll = 0;

        this.maxScroll = mobx.computed(function () {
            const {scrollY, screenHeight} = this.getScreenInfo();
            this._maxScroll = Math.max(scrollY + screenHeight, this._maxScroll);
            return this._maxScroll;
        }, {context: this});
    }
    Private.prototype = {
        getScreenInfo() {
            const scrollAspect = this._siteAPI.getSiteAspect('windowScrollEvent');
            const scrollPosition = scrollAspect.getScrollPosition();
            return {
                scrollY: scrollPosition.y,
                screenHeight: this._siteData.getScreenHeight() || DEF_HEIGHT
            };
        },

        isEnabled(isFirstPageShown, primaryPageId) { // eslint-disable-line complexity
            if (this._siteData.isInSSR() || !this._siteData.isViewerMode()) {
                return false;
            }
            // eslint-disable-next-line no-extra-parens
            if (typeof window === 'undefined' || (!window.clientSideRender && isFirstPageShown)) {
                return false;
            }
            const actions = this._siteAPI.getSiteAspect('actionsAspect');
            if (actions.hasNextAnchorScroll()) {
                return false;
            }
            const pointers = this._siteAPI.getPointers();
            const displayedDal = this._siteAPI.getDisplayedDAL();
            const pagePointer = pointers.components.getPage(primaryPageId, this._siteData.getViewMode());
            return !displayedDal.get(pointers.getInnerPointer(pagePointer, ['layout', 'anchors']), true);
        },

        done() {
            try {
                const event = new window.CustomEvent('wixFullyRendered', {
                    detail: {
                        progressive: this._isEnabled
                    }
                });
                window.dispatchEvent(event);
            } catch (e) {
                // empty
            }
            this._siteAPI.notifyFullyRendered();
        },

        reset() {
            const primaryPageId = this._siteData.getPrimaryPageId();
            if (primaryPageId !== this._primaryPageId) {
                const isFirstPageShown = !this._primaryPageId;
                this._primaryPageId = primaryPageId;
                this._notRevealedCount = 0;
                const {scrollY, screenHeight} = this.getScreenInfo();
                this._revealLimit.set(scrollY + screenHeight);
                this._isEnabled = this.isEnabled(isFirstPageShown, primaryPageId);

                this.get = this._isEnabled ?
                    function () {
                        return Math.max(this.maxScroll.get(), this._revealLimit.get()) + EXTRA_REVEAL;
                    } :
                    () => Number.MAX_SAFE_INTEGER;
            }
        },

        reveal() {
            if (this._revealId === 0) {
                this._revealId = idleCallback.request(idleDeadline => {
                    this._revealId = 0;

                    const {screenHeight} = this.getScreenInfo();
                    const limit = Math.max(this.maxScroll.get(), this._revealLimit.get() + Math.round(screenHeight / this._renderProgressPart));
                    this._revealLimit.set(limit);

                    if (this._notRevealedCount > 0) {
                        // If we have extra time we can do more work
                        if (idleDeadline && idleDeadline.timeRemaining) {
                            this._renderProgressPart -= idleDeadline.timeRemaining() > 0 ? RENDER_PROGRESS_PART_DELTA : -RENDER_PROGRESS_PART_DELTA;
                            if (this._renderProgressPart <= 0) {
                                this._renderProgressPart = 1;
                            }
                        }
                        this.reveal();
                    }
                });
            }
        },

        incNotRevealedCount() {
            return this._notRevealedCount++;
        },
        decNotRevealedCount() {
            const count = this._notRevealedCount--;
            if (count === 1 && this._rendered) {
                this.done();
            }
            return count;
        },

        onRendered() {
            if (this._notRevealedCount === 0) {
                this.done();
            } else {
                this._rendered = true;
            }
        }
    };

    function buildPrivate(siteAPI) {
        const siteData = siteAPI.getSiteData();
        let prv = privates.get(siteData);
        if (!prv) {
            prv = new Private(siteAPI, siteData);
            privates.set(siteData, prv);
        }
        return prv;
    }

    const getPrivate = siteAPI => privates.get(siteAPI.getSiteData());

    return {
        reset(siteAPI) {
            buildPrivate(siteAPI).reset();
        },

        get(siteAPI) {
            return getPrivate(siteAPI).get();
        },

        incNotRevealedCount(siteAPI) {
            return getPrivate(siteAPI).incNotRevealedCount();
        },
        decNotRevealedCount(siteAPI) {
            return getPrivate(siteAPI).decNotRevealedCount();
        },

        auto(siteAPI) {
            getPrivate(siteAPI).reveal();
        },

        onRendered(siteAPI) {
            buildPrivate(siteAPI).onRendered();
        }
    };
});
