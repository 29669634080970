define([
    'santa-components'
], function (
    santaComponents
) {
    'use strict';

    const extendClass = CompClass => {
        if (CompClass.behaviors || CompClass.behaviorExtensions) {
            return santaComponents.HOC.withBehaviors(CompClass.behaviors || CompClass.behaviorExtensions)(CompClass);
        }

        return CompClass;
    };

    return {
        extendClass
    };
});
