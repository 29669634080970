define([
    'lodash',
    'react',
    'santaProps',
    'prop-types',
    'mobx-react',
    'santa-components'
], function (
    _,
    React,
    santaProps,
    PropTypes,
    mobxReact,
    santaComponents
) {
    'use strict';

    const cache = new WeakMap();
    const computedPropsBuilder = santaProps.computedPropsBuilder;

    const resolveSantaTypes = (santaTypes, santaTypeIdentifier, props, compClass, invalidateCache) => {
        const {siteAPI} = props;
        const siteData = siteAPI.getSiteData();
        const {skin} = compClass.propTypes;
        const hardcodedSkin = skin && skin.fetch && skin.fetch();
        const compProps = _.defaults({siteData, hardcodedSkin}, props);
        const compState = {
            fetchSantaType: (santaTypesDefinition, fetcherState, fetcherProps) => siteAPI.getSantaFetcher(santaTypesDefinition)(fetcherState, fetcherProps),
            siteData,
            siteAPI
        };

        const computedProps = computedPropsBuilder.getInstance(compState, compProps, santaTypes, santaTypeIdentifier, false).getComputedProps(compProps, invalidateCache);
        return computedProps.get();
    };

    const buildStyleProps = (props, compClass, invalidateCache) => {
        const cssTypes = _.assign({}, compClass.getCompCss.cssTypes, {styleId: compClass.propTypes.styleId});

        return resolveSantaTypes(cssTypes, 'cssTypes', props, compClass, invalidateCache);
    };

    const getFontData = (styleIds, compClass, props) => {
        const fontsProps = resolveSantaTypes(compClass.getCompFonts.fontsTypes, 'fontsTypes', props, compClass);
        const fontsData = compClass.getCompFonts(styleIds, fontsProps);

        return _.union(fontsData);
    };

    const isComponentExists = ({siteAPI, compId, rootId}) => {
        const displayedDAL = siteAPI.getDisplayedDAL();
        const pointers = siteAPI.getPointers();
        const siteData = siteAPI.getSiteData();
        const pagePointer = pointers.components.getPage(rootId, siteData.getViewMode());
        const componentPointer = pointers.components.getComponent(compId, pagePointer);
        return displayedDAL.isExist(componentPointer);
    };

    const styleNodeFactory = (name, compClass) => {
        let cachedCompClass = cache.get(compClass);
        if (cachedCompClass) {
            const cachedComp = cachedCompClass[name];
            if (cachedComp) {
                return cachedComp;
            }
        } else {
            cachedCompClass = {};
            cache.set(compClass, cachedCompClass);
        }

        class styleNode extends React.Component {
            constructor(props) {
                super(props);
                this.previousStyleNode = null;
            }

            render() {
                const {props} = this;
                if (!props.structure && !isComponentExists(props)) {
                    return this.previousStyleNode;
                }

                const styleProps = buildStyleProps(_.assign({id: props.compId}, props), compClass, props.invalidateCache);
                const cssData = compClass.getCompCss(styleProps.styleId, styleProps);

                if (!cssData) {
                    return this.previousStyleNode;
                }

                const {compId, siteAPI, requestRelayout} = props;
                if (requestRelayout && compClass.getCompFonts) {
                    const fontsLoaderAspect = siteAPI.getSiteAspect('fontsLoaderAspect');
                    if (fontsLoaderAspect) {
                        const fontsFromLoadedStyles = getFontData(_.keys(cssData), compClass, _.assign({id: compId}, props));
                        fontsLoaderAspect.loadFonts(fontsFromLoadedStyles, requestRelayout);
                    }
                }

                const currentStyleNode = _.map(cssData, (css, innerStyleId) => santaComponents.utils.styleNodeUtils.generateStyleNode(innerStyleId, css, props.styleRoot));
                this.previousStyleNode = currentStyleNode;

                return currentStyleNode;
            }
        }

        styleNode.displayName = `StyleNode (${name})`;
        styleNode.propTypes = {
            styleRoot: PropTypes.string,
            siteAPI: PropTypes.object.isRequired,
            structure: PropTypes.object,
            compId: PropTypes.string,
            rootId: PropTypes.string.isRequired,
            requestRelayout: PropTypes.func.isRequired,
            invalidateCache: PropTypes.bool
        };
        styleNode.defaultProps = {rootId: 'masterPage'};

        const result = mobxReact.observer(styleNode);
        cachedCompClass[name] = result;
        return result;
    };

    return styleNodeFactory;
});
