define([
    'componentsCore',
    'compUtils/core/wixHOC'
], function (componentsCore, wixHOC) {
    'use strict';

    componentsCore.compRegistrar.registerWixHOC(wixHOC);

    return componentsCore.compRegistrar;
});
