define(['lodash', 'mobx', 'coreUtils'], function (_, mobx, coreUtils) {
    'use strict';

    const STRUCTURE_PROPERTIES_WITHOUT_COMPONENTS = _(coreUtils.constants.COMP_DATA_QUERY_KEYS)
        .values()
        .concat(['id', 'componentType', 'type', 'modes', 'layout', 'skin', 'styleId', 'originalStyleId', 'originCompId'])
        .value();

    function defineNonEnumerableProperties(obj, props) {
        _.forOwn(props, function (value, key) {
            Object.defineProperty(obj, key, {
                value,
                enumerable: false
            });
        });
    }

    function MagicStructure(siteData, displayedDAL, pointers, compId, computedPagePointer, computedChildrenToRender, computedParentDimensions) {
        defineNonEnumerableProperties(this, {
            cachedComputedStructureValues: {},
            siteData,
            displayedDAL,
            pointers,
            compId,
            computedPagePointer,
            computedChildrenToRender,
            computedParentDimensions
        });
    }

    function getStructureValue(property) {
        if (this.cachedComputedStructureValues[property]) {
            return this.cachedComputedStructureValues[property].get();
        }

        const computedStructureVal = mobx.computed(function () {
            const compPointer = this.pointers.components.getComponent(this.compId, this.computedPagePointer.get());

            if (!compPointer || !this.displayedDAL.isExist(compPointer)) {
                return null;
            }
            const propertyPointer = this.pointers.getInnerPointer(compPointer, [property]);
            return this.displayedDAL.getStructureProperty(propertyPointer);
        }, {context: this, compareStructural: true, name: `${this.compId}_MagicStructure_${property}`});

        this.cachedComputedStructureValues[property] = computedStructureVal;

        return computedStructureVal.get();
    }

    MagicStructure.prototype = _.transform(STRUCTURE_PROPERTIES_WITHOUT_COMPONENTS, function (acc, property) {
        Object.defineProperty(acc, property, {
            get() {
                return getStructureValue.call(this, property);
            }
        });
    }, {});

    Object.defineProperty(MagicStructure.prototype, 'components', {
        get() {
            if (this.cachedComputedStructureValues.components) {
                return this.cachedComputedStructureValues.components.get();
            }

            const computedStructureVal = mobx.computed(function () {
                return _.map(this.computedChildrenToRender.get(), 'id');
            }, {context: this, compareStructural: true, name: `${this.compId}_MagicStructure_components`});

            this.cachedComputedStructureValues.components = computedStructureVal;

            return computedStructureVal.get();
        }
    });

    Object.defineProperty(MagicStructure.prototype, 'dimensions', {
        get() {
            if (this.cachedComputedStructureValues.dimensions) {
                return this.cachedComputedStructureValues.dimensions.get();
            }

            const dimensions = mobx.computed(function () {
                if (coreUtils.positionAndSize.isVerbsLayout(this.layout)) {
                    return coreUtils.positionAndSize.getVerbsPositionAndSize(this.layout, this.computedParentDimensions.get(), this.siteData.getScreenSize(), this.siteData.getSiteWidth());
                }

                return coreUtils.positionAndSize.getAbsolutePositionAndSize(this.layout);
            }, {context: this, compareStructural: true, name: `${this.compId}_MagicStructure_dimensions`});

            this.cachedComputedStructureValues.dimensions = dimensions;

            return dimensions.get();
        }
    });

    return MagicStructure;
});
