define([
    'lodash',
    'prop-types',
    'santa-components',
    'compUtils/core/styleNodeFactory',
    'compUtils/core/wixHOCEnhancer'
], function (
    _,
    PropTypes,
    santaComponents,
    styleNodeFactory,
    wixHOCEnhancer
) {
    'use strict';

    const buildStyleNodeProps = ({id, rootId, styleRoot, structure, siteAPI, mobxObserverWrapperProps}) => ({
        key: `${id}_STYLE`,
        styleRoot: styleRoot ? styleRoot : mobxObserverWrapperProps.styleRoot,
        siteAPI: siteAPI ? siteAPI : mobxObserverWrapperProps.siteAPI,
        requestRelayout: mobxObserverWrapperProps.requestRelayout,
        structure,
        compId: id,
        rootId
    });

    const buildCompProps = props => _.assign({}, _.omit(props, 'compRef'), {key: props.id, ref: props.compRef});


    const wixHOC = (name, compClass, mobxDefinition) => {
        const CompMobxClass = mobxDefinition(name, wixHOCEnhancer.extendClass(compClass));
        const StyleClass = styleNodeFactory(name, compClass);

        const Comp = props => [
            santaComponents.utils.createReactElement(CompMobxClass, buildCompProps(props)),
            compClass.getCompCss ? santaComponents.utils.createReactElement(StyleClass, buildStyleNodeProps(props)) : null
        ];

        Comp.propTypes = {
            styleRoot: PropTypes.string,
            compRef: PropTypes.func,
            siteAPI: PropTypes.object,
            structure: PropTypes.object,
            id: PropTypes.string,
            rootId: PropTypes.string,
            mobxObserverWrapperProps: PropTypes.PropTypes.shape({
                siteAPI: PropTypes.object.isRequired,
                requestRelayout: PropTypes.func.isRequired,
                viewerPrivateServices: PropTypes.object,
                addComponentRef: PropTypes.func,
                styleRoot: PropTypes.string
            }).isRequired
        };

        return Comp;
    };


    return wixHOC;
});
