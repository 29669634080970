define(['lodash', 'coreUtils'], function (_, coreUtils) {
    'use strict';

    const isOnlyOneKeyChanged = (prevObj, nextObj, keyToCompare) => {
        const propsDiff = _.pickBy(nextObj, function (val, key) {
            return prevObj[key] !== val && !_.isEqual(prevObj[key], val);
        });

        return _.size(propsDiff) === 1 && !!propsDiff[keyToCompare];
    };

    function getZeptoCompatibleStylePrefix(prefix) {
        return prefix.replace(/[A-Z]/g, l => `-${l.toLowerCase()}`);
    }

    const convertToZeptoCompatibleStyles = styles => {
        const prefixedTransform = coreUtils.style.getPrefixedTransform();

        if (!_.has(styles, [prefixedTransform])) {
            return styles;
        }

        const compatibleStyles = _.clone(styles);

        const zeptoCompatiblePrefixedTransform = getZeptoCompatibleStylePrefix(prefixedTransform);
        compatibleStyles[zeptoCompatiblePrefixedTransform] = styles[prefixedTransform];
        if (prefixedTransform !== zeptoCompatiblePrefixedTransform) {
            compatibleStyles[prefixedTransform] = '';
        }

        return compatibleStyles;
    };

    const isOnlyStyleChanged = (prevProps, nextProps) => isOnlyOneKeyChanged(prevProps, nextProps, 'style');
    const isOnlyTopChanged = (prevStyle, nextStyle) => isOnlyOneKeyChanged(prevStyle, nextStyle, 'top');

    const getStylesToApplyDomOnly = (prevProps, nextProps, {compSpecificIsDomOnlyOverride} = {}) => {
        const onlyStyleWasChanged = isOnlyStyleChanged(prevProps, nextProps);

        if (onlyStyleWasChanged && isOnlyTopChanged(prevProps.style, nextProps.style)) {
            const styleToApply = {top: nextProps.style.top};
            return convertToZeptoCompatibleStyles(styleToApply);
        }
        let canUpdateDomOnly = onlyStyleWasChanged && nextProps.style.display === prevProps.style.display;

        if (_.isFunction(compSpecificIsDomOnlyOverride)) {
            canUpdateDomOnly = canUpdateDomOnly && compSpecificIsDomOnlyOverride(prevProps, nextProps);
        }

        return canUpdateDomOnly ? convertToZeptoCompatibleStyles(nextProps.style) : null;
    };

    return {
        getStylesToApplyDomOnly
    };
});
