define([
    'componentsCore',
    'compUtils/core/compFactory',
    'compUtils/core/styleNodeFactory',
    'compUtils/core/mobxObserverWrapper',
    'compUtils/core/progressiveReveal'
],
function (
    componentsCore,
    compFactory,
    styleNodeFactory,
    mobxObserverWrapper,
    progressiveReveal
) {
    'use strict';


    if (typeof window !== 'undefined') {
        componentsCore.compRegistrar.registerMobxObserver(mobxObserverWrapper);
    }
    return {
        compFactory,
        styleNodeFactory,
        mobxObserverWrapper,
        progressiveReveal
    };
});
